import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      PagamentosBaixa: imageSharp(fluid: { originalName: { regex: "/pagamentos-baixa/" } }) {
        fixed(width: 44, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
      error404: imageSharp(fluid: { originalName: { regex: "/404/" } }) {
        fixed(width: 400, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
