import React from 'react'

import { Helmet } from 'react-helmet'

type Meta = {
  name?: string
  property?: string
  content?: string
}

interface ISeo {
  title: string
  meta?: Meta[]
  url?: string
  ampurl?: string
  noIndex?: boolean
}

const organizationSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Inter',
  alternateName: 'Banco Inter',
  url: 'https://blog.bancointer.com.br',
  logo: 'https://marketing.bancointer.com.br/comunicacao/e-mail-mkt-institucional/imagens/banco-inter.png',
  sameAs: [
    'https://www.bancointer.com.br',
    'https://www.gointer.com',
    'https://www.facebook.com/inter-invest',
    'https://twitter.com/inter-invest',
    'https://www.instagram.com/portalinter-invest/',
    'https://www.youtube.com/c/inter-invest',
    'https://www.linkedin.com/company/banco-inter/mycompany/',
  ],
})

const searchSchema = JSON.stringify({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: 'Inter Invest',
  url: 'https://blog.bancointer.com.br',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://blog.bancointer.com.br/search?q={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
})

const tagManagerScript =
  "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
  "    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
  "    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
  "    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
  "    })(window,document,'script','dataLayer','GTM-NQ6QRPK');"

const SEO = ({ title, url, meta, noIndex }: ISeo) => (
  <>
    <Helmet
      htmlAttributes={{
        lang: 'pt-BR',
      }}
      title={title}
      titleTemplate={title}
      meta={meta}
    >
      <script type="application/ld+json">{organizationSchema}</script>
      <script type="application/ld+json">{searchSchema}</script>
      <script type="text/javascript">{tagManagerScript}</script>
      <link rel="canonical" href={url} />
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
    <noscript>
      <iframe
        title="google manager"
        src="https://www.googletagmanager.com/ns.html?id=GTM-NQ6QRPK"
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  </>
)

SEO.defaultProps = {
  meta: [],
}

export default SEO
