import React from 'react'

import { Link } from 'gatsby'
import Img from 'gatsby-image'

// Data
import categoriesJSON from 'src/assets/data/categories.json'

// Components
import SEO from 'src/components/Seo'

// PageQuery
import usePageQuery from '../pageQuery'
import Layout from '../../components/Layout'

// Types
type categoriesProps = {
  id: string
  name: string
  slug: string
  template: string
  subTitle: string
  icon: string
  colors: colorsProps
}

type colorsProps = {
  primary: string
  primary300: string
  primary200: string
  primary100: string
}

const NotFoundPage = () => {
  const data = usePageQuery()
  return (
    <Layout>
      <SEO
        title="Página não encontrada Portal Inter Invest"
        url={`${process.env.BASE_URL}/404`}
        meta={[
          {
            property: 'robots',
            content: 'noindex',
          },
        ]}
      />
      <div className="container content-404 mt-4 mt-md-5 py-5 text-center text-lg-left">
        <div className="row">
          <div className="col-12">
            <h3 className="fs-30 fs-lg-42 text-grayscale--500">Ops... página não encontrada!</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center">
            <Img className="img-fluid" fixed={data.error404.fixed} alt="404 image" />
          </div>
          <div className="col-sm-12 col-lg-7 col-xl-6 order-lg-1">
            <p className="fs-18 lh-22 fs-lg-20 text-grayscale--400">
              Aqui estão alguns links que você pode estar procurando:
            </p>
            <ul className="list-unstyled link-list--404 fw-400">
              {categoriesJSON.map((item: categoriesProps) => (
                <li key={item.slug}>
                  <Link to={`/${item.slug}`} title={item.name}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
